<template>
  <div id="video">
    <div class="title gn-full-x gn-flex-center">公司动态</div>
    <div class="tabs">
      <div>公司新闻</div>
      <div class="active">市场巡展</div>
    </div>
    <div class="title news-title gn-full-x gn-flex-center">{{ name }}</div>
    <div class="times">{{ time }}</div>
    <div class="gn-bg-black gn-full-x gn-flex-center">
      <video ref="video" style="width: 1444px; height: 655px" controls autoplay>
        <source :src="videoUrl" type="video/mp4" />
        <source :src="videoUrl" type="video/ogg" />
        <source :src="videoUrl" type="video/webm" />
      </video>
    </div>

    <other-video :list="videos" @change="changeVideo" />
  </div>
</template>

<script>
import { getArticleList } from '@/api/admin'
import { getPublishListByType } from '@/api/officialWebsite'
import otherVideo from './components/otherVideo'

export default {
  name: 'video',
  components: {
    otherVideo
  },
  metaInfo () {
    return {
      title: this.$route.query.name
    }
  },
  data () {
    return {
      advisory_type: '',
      title: '',
      videoUrl: '',
      name: '',
      videos: [],
      videosCover: [
        require('@/assets/images/newbazaarIntroduce/instructions1.png'),
        require('@/assets/images/newbazaarIntroduce/instructions2.png'),
        require('@/assets/images/newbazaarIntroduce/instructions3.png'),
        require('@/assets/images/newbazaarIntroduce/instructions4.png'),
        require('@/assets/images/newbazaarIntroduce/instructions5.png'),
        require('@/assets/images/newbazaarIntroduce/instructions6.png')
      ],
      reqParams: {
        page: 1,
        per_page: 100,
        search_data: '', // 关键字搜索
        advisory_type: '3', // 类型 1 公司新闻 2 行业动态 3 视频展示
        status: '', // 0 正常 1 停用 2 删除
        start_time: '', //  开始时间
        end_time: '', // 结束时间
        is_platform: '1'
      },
      time: ''
    }
  },
  created () {
    const { name, videoUrl, type, time } = this.$route.query

    this.advisory_type = type
    this.videoUrl = videoUrl || ''
    this.name = name || ''
    this.time = time || ''

    this.getVideoData(type)
  },
  methods: {
    async getVideoData (type) {
      if (type === '3') {
        // 视频展示
        this.reqParams.advisory_type = this.advisory_type
        const { list } = await getArticleList(this.reqParams)

        this.videos = (list || []).map((item) => {
          return {
            ...item,
            name: item.title,
            imgs: item.title_image // 封面图
          }
        })
      } else if (type === '4') {
        // 使用说明
        const data = await getPublishListByType({
          publish_type: ['7'] // 7:使用说明
        })
        this.videos = (data[7] || []).map((item, index) => {
          return {
            ...item,
            imgs: this.videosCover[index] // 封面图
          }
        })
      }
    },
    changeVideo ({ name, file }) {
      this.videoUrl = file
      this.name = name || ''

      this.$nextTick(() => {
        this.$refs.video.load()

        // 此段代码的目的仅仅是为了切换浏览器地址栏的参数信息
        this.$router.push({
          name: 'video',
          query: {
            type: this.advisory_type,
            videoUrl: file,
            name
          }
        })
      })
    }
  }
}
</script>

<style lang='scss' scoped>
#video {
  margin-top: 68px;
  padding: 45px 238px;
  .title {
    font-size: 48px;
    font-weight: 700;
    color: #333334;
    margin-bottom: 45px;
  }
  .tabs {
    display: flex;
    justify-content: center;
    width: 100%;

    div {
      padding-bottom: 20px;
      box-sizing: border-box;
      font-size: 24px;
      font-family: Source Han Sans CN, Source Han Sans CN-Bold;
      text-align: justifyLeft;
      color: #333334;
      line-height: 51px;
    }
    div:nth-child(1) {
      margin-right: 60px;
    }
    .active {
      font-weight: 700;
      border-bottom: 2px solid #00a0ac;
    }
  }
  .news-title {
    font-size: 30px;
    border-top: 1px solid #ccc;
    padding-top: 50px;
  }
  .times {
    margin-bottom: 50px;
    text-align: center;
  }
}
</style>
